
import Vue from "vue";
import {
  AssociateRoles,
  associates,
  AssociateStatus,
  IAssociate,
  IListInfo,
  multiApiOperations,
} from "@/api";
import { Actions } from "@/store/models";
import { getYear, toPascalCase } from "@/utils";
import { permissions } from "@/mixins";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";

export default Vue.extend({
  name: "GssiAdminUsers",
  components: {
    ConfirmationDialog,
  },
  mixins: [permissions],
  data() {
    return {
      headers: [
        { text: "USERS", value: "user" },
        // { text: "ACCOUNT STATUS", value: "status" },
        { text: "EMAIL", value: "user_name" },
        { text: "PHONE NUMBER", value: "phone_number" },
        { text: "PERMISSIONS", value: "permissions" },
        { text: "", value: "action" },
      ],
      getYear: getYear,
      items: [] as IAssociate[],
      searchQuery: "",
      enablePrev: false,
      enableNext: false,
      listInfo: {} as IListInfo,
      deleteUserDialog: false,
      selected: 0,
    };
  },

  computed: {
    filteredItems: function (): IAssociate[] {
      return this.items.filter((item) => {
        return (
          item.first_name
            ?.toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          item.last_name
            ?.toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          item.user_name?.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  created() {
    this.getItems();
  },
  methods: {
    // pagination
    next() {
      this.getItems(this.listInfo.start_index + 100);
    },
    prev() {
      this.getItems(this.listInfo.start_index - 100);
    },

    async getItems(start_index?: number) {
      try {
        const response = await associates.getAllByCompany(undefined as any, {
          start_index,
          row_count: 100
        });
        this.items = response.data.filter((item) => {
          return item.associate_type.name !== "GSSI_ADMIN";
        });
        this.items.forEach((item) => {
          (item as any).user = item.first_name + " " + item.last_name; //for sorting
          (item as any).permissions = this.getPermissions(item);
        });
        this.listInfo = response.list_info!;
        this.enablePrev = this.listInfo.start_index === 1 ? false : true;
        this.enableNext = this.listInfo.has_more_rows ? true : false;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    getPermissions(item: IAssociate) {
      if (!item.roles) return "None";
      const roles = item.roles.map((r) => {
        if (r === AssociateRoles.ManageAccount) {
          return "Account";
        }
        return toPascalCase(r.split("_")[1]);
      });
      return roles.join(" | ");
    },
    getStatus(item: IAssociate) {
      if (!item.status) return "UNKNOWN";
      return AssociateStatus[item.status];
    },
    view(item: IAssociate) {
      this.$router.push(`/gssiadmin/${item.company.id}/users/${item.id}`);
    },
    remove(item: IAssociate) {
      this.selected = item.id;
      this.deleteUserDialog = true;
    },
    async removeUser() {
      try {
        await multiApiOperations(associates.delete, [this.selected]);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      } finally {
        this.getItems();
        this.selected = 0;
      }
    },
  },
});
